import React, { useState } from "react";
import styles from "../SettingPermission/Settingpermission.module.css";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../redux/slices/userSlice";
import deleteimage from "../../assets/image/deleteimage.png";
import cancelBtn from "../../assets/image/cancelBtn.png";
import Modal from "react-bootstrap/Modal";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
import Toster from "../../Toster/Toster";
function CargiverDeleteModel({
  show,
  handleClose,
  caregiversId,
  searchTerm,
  pageno,
  caregiver,
}) {
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const deleteCaregiver = (id) => {
    AdminIndependicareApi.deletCaregivers(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        if (response?.code == STATUS_CODE.SUCCESS) {
          Toster(t("CAREGIVER_DELETE_SUCCESSFULLY"), "success");
          caregiver(pageno, searchTerm);
          handleClose();
        } else if (
          response.code == STATUS_CODE.VALIDATION_ERROR ||
          response.data.code === STATUS_CODE.VALIDATION_ERROR
        ) {
          Toster(response?.data?.message, "error");
        } else {
          const message = t(ResponseCode).replace("/Caregiver", "");
          Toster(message, "error");
        }
      }
    });
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{t("ALERT")}</Modal.Title>
        <img
          src={cancelBtn}
          onClick={handleClose}
          className={styles.cancelBtn}></img>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.caregiverSourcePopup}>
          <img
            src={deleteimage}
            alt="deleteimage"
            className={styles.deleteImageModel}></img>
          <p>
            {t(
              // "CAN'T_DELETE_CAREGIVER_IS_ASSOCIATED_TO_SENIOR_PERSON_OF_STAKEHOLDER"
              "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CAREGIVER?"
            )}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.addNewTitle}>
        <Button
          variant=""
          onClick={() => {
            deleteCaregiver(caregiversId);
          }}>
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CargiverDeleteModel;
