import { Navigate, Routes, Route } from "react-router-dom";
import "../assets/css/style.css";
import Home from "../components/Home/Home";
import Stakeholderslist from "../components/Stakeholderslist/Stakeholderslist";
import StakeholderDetails from "../components/StakeholderDetails/StakeholderDetails";
import SettingPermission from "../components/SettingPermission/SettingPermission";
import Login from "../components/Login/Login";
import ForgotPassword from "../components/Login/ForgotPassword";
import ResetPassword from "../components/Login/ResetPassword";
import CaregiverSources from "../components/SettingPermission/CaregiverSources";
import LocationType from "../components/SettingPermission/LocationType ";
import SubLocationType from "../components/SettingPermission/SubLocationType";
import Profile from "../components/Header/Profile";
import Caregiverslist from "../components/Stakeholderslist/Caregiverslist";
import CaregiversDetails from "../components/StakeholderDetails/CaregiversDetails";
import PrivacyPolicy from "../components/SettingPermission/PrivacyPolicy";
import TermsConditions from "../components/SettingPermission/TermsConditions";
import { useSelector } from "react-redux";

const Router = () => {
  const userToken = useSelector((state) => state.user.userToken);
  const userProfile = useSelector((state) => state.user.profileImg);
  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route
          path="/"
          element={userToken ? <Navigate to="/home" /> : <Login />}
        />

        <Route path="/send-code" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Private Routes */}
        <Route
          path="/home"
          element={userToken ? <Home /> : <Navigate to="/" />}
        />
        <Route
          path="/profile"
          element={userToken ? <Profile /> : <Navigate to="/" />}
        />
        <Route
          path="/stakholderlist"
          element={userToken ? <Stakeholderslist /> : <Navigate to="/" />}
        />
        <Route
          path="/stakeholderdetails/:id"
          element={userToken ? <StakeholderDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/caregivers-details/:id"
          element={userToken ? <CaregiversDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/caregivers-list"
          element={userToken ? <Caregiverslist /> : <Navigate to="/" />}
        />
        <Route
          path="/setting-permission"
          element={userToken ? <SettingPermission /> : <Navigate to="/" />}
        />
        <Route
          path="/caregiver-sources"
          element={userToken ? <CaregiverSources /> : <Navigate to="/" />}
        />
        <Route
          path="/location-type"
          element={userToken ? <LocationType /> : <Navigate to="/" />}
        />
        <Route
          path="/sub-location-type"
          element={userToken ? <SubLocationType /> : <Navigate to="/" />}
        />
        <Route
          path="/privacy-policy"
          element={userToken ? <PrivacyPolicy /> : <Navigate to="/" />}
        />
        <Route
          path="/terms-conditions"
          element={userToken ? <TermsConditions /> : <Navigate to="/" />}
        />

        {/* Catch-all route for unknown paths */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default Router;
