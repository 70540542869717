import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import styles from "../SettingPermission/Settingpermission.module.css";
import arrow from "../../assets/image/arrow-back.png";
import { Dropdown, Table, DropdownButton } from "react-bootstrap";
import horizontal from "../../assets/image/horizontal.png";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import search from "../../assets/image/search.png";
import AdminIndependicareApi from "../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import cancelBtn from "../../assets/image/cancelBtn.png";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { NavLink } from "react-router-dom";
import delete_icon from "../../assets/image/delete_icon.svg";
import Edit from "../../assets/image/Edit.svg";
import { useNavigate } from "react-router-dom";
import deleteimage from "../../assets/image/deleteimage.png";
import AddCaregiverSources from "./AddCaregiverSources";
import StatusCaregiverSources from "./StatusCaregiverSources";
import DeleteCaregiverSourcesModel from "./DeleteCaregiverSourcesModel";
import Loader from "../Loader/Loader";
import { ACTIVE_TYPE } from "../MockData/Mockdata";
import { userLogoutClear } from "../../redux/slices/userSlice";

function CaregiverSources() {
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);
  const [resourceId, setResourceId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userToken = useSelector((state) => state.user.userToken);
  // delete popup funtion
  const [deleteshow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  // chnageStaus popup funtion
  const [statusshow, setStatusShow] = useState(false);
  const handleStatusClose = () => setStatusShow(false);
  const handleStatusShow = () => setStatusShow(true);
  const [caregiverStatus, setCaregiverStatus] = useState(false);
  // serch bar states
  const [searchTerm, setSearchTerm] = useState("");
  // Filter the data based on the searchTerm
  const filteredData = list.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = () => {
    setIsLoading(true);
    AdminIndependicareApi.fetchCaregiverSource(userToken, searchTerm).then(
      (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setList(response?.data?.caregiver_source);
        }
      }
    );
  };

  useEffect(() => {
    if (isEdit && resourceId) {
      AdminIndependicareApi.fetchCaregiverSourceDetail(
        userToken,
        resourceId
      ).then((response) => {
        setValue("TITLE", response?.data?.caregiver_source?.name);
      });
    } else {
      setValue("TITLE", "");
    }
  }, [isEdit, resourceId]);

  return (
    <>
      <div>
        {isLoading && <Loader />}
        <div className={styles.settingMainSection}>
          <div className={styles.mainSidebar}>
            <div className={styles.seetingLeftSidebar}>
              <Sidebar />
            </div>
            {/******************RightSide bar*****************/}
            <div className={styles.seetingRightSidebar}>
              <Header />
              <div className={styles.sechSeetingmain}>
                <div className={styles.seetingArrowImage}>
                  <NavLink to="/home">
                    <img src={arrow} className={styles.seetingBackArrow}></img>
                  </NavLink>
                  <div className={styles.serchIconImage}>
                    <Form.Control
                      size="sm"
                      type="search"
                      placeholder={t("PLACRHOLDER_SEARCH")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <img
                      src={search}
                      alt="serchIcon"
                      className={styles.settingSerchIcon}></img>
                  </div>
                </div>
                <div className={styles.addNewButton}>
                  <Button
                    variant=""
                    type="submit"
                    onClick={() => {
                      handleShow();
                      setIsEdit(false);
                      setResourceId("");
                    }}>
                    {t("ADD_NEW")}
                  </Button>
                </div>
              </div>
              {filteredData?.length !== 0 ? (
                <div className={styles.settingPermissionTable}>
                  <Table className="customTable" striped bordered>
                    {isLoading && <Loader />}
                    <thead>
                      <tr>
                        <th className={styles.settingBackground}>
                          {t("TITLE")}
                        </th>
                        <th className={styles.settingBackground}>
                          {t("STATUS")}
                        </th>

                        <th className={styles.settingBackgroundAction}>
                          {t("ACTION")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className={styles.caregiverSourceText}>
                              {item.name}
                            </td>
                            <td className={styles.caregiverSourceText}>
                              {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                                ? "Active"
                                : "Inactive"}
                            </td>
                            <td className={styles.settingDotImage}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className={styles.doteImage}>
                                  <img
                                    src={horizontal}
                                    alt="horizontal"
                                    className={styles.careGiverDoteImage}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    href="#"
                                    className={styles.caregiverdropdownItem}
                                    onClick={() => {
                                      handleShow(index);
                                      setIsEdit(true);
                                      setResourceId(item.id);
                                    }}>
                                    <spen>{t("EDIT")}</spen>
                                    <img
                                      src={Edit}
                                      alt="Edit"
                                      className={styles.icon}
                                    />
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#"
                                    className={styles.caregiverdropdownItem}
                                    onClick={() => {
                                      // handleDelete(item.id);
                                      handleDeleteShow();
                                      setResourceId(item.id);
                                    }}>
                                    <span>{t("DELETE")}</span>
                                    <img
                                      src={delete_icon}
                                      alt="Delete"
                                      className={styles.icon}
                                    />
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() => {
                                      handleStatusShow();
                                      setResourceId(item.id);
                                      setCaregiverStatus(item.is_active);
                                    }}
                                    className={styles.caregiverdropdownItem}>
                                    <span>
                                      {item.is_active === 1
                                        ? "Inactive"
                                        : "Active"}

                                      {/* {t("STATUS")} */}
                                    </span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                !isLoading && (
                  <p className={styles.listRecoredText}>
                    {t("RECORD_NOT_FOUND")}
                  </p>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {/*********Add Title Popup Model******* */}
      <AddCaregiverSources
        show={show}
        handleClose={handleClose}
        handleGetData={handleGetData}
        resourceId={resourceId}
        isEdit={isEdit}
        reset={reset}
      />
      {/*********delete Title Popup Model******* */}
      <DeleteCaregiverSourcesModel
        deleteshow={deleteshow}
        handleDeleteClose={handleDeleteClose}
        resourceId={resourceId}
        handleGetData={handleGetData}
      />
      {/********chnage the staus active inactive */}
      <StatusCaregiverSources
        statusshow={statusshow}
        handleStatusClose={handleStatusClose}
        caregiverStatus={caregiverStatus}
        handleGetData={handleGetData}
        resourceId={resourceId}
      />
      <Footer />
    </>
  );
}

export default CaregiverSources;
