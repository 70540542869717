import React, { useState } from "react";
import { Button, Offcanvas, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";
import styles from "../Sidebar/Sidebar.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import arrowUpImage from "../../assets/image/arrowUpImage.png";
import arrowNewImage from "../../assets/image/arrowNewImage.png";
import logo from "../../assets/image/logo.png";

import { useLocation, useNavigate } from "react-router-dom";
function Sidebar() {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const { register, handleSubmit, formState } = useForm({ mode: "onChange" });
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [activeTab, setActiveTab] = useState(null);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleItemClick = (path, index) => {
    handleNavigation(path);
    setActiveTab(index);
    setDropdownOpen(false); // Close the dropdown after clicking an item
  };
  const dropDownItem = [
    {
      path: "/caregiver-sources",
      title: "CAREGIVER_SOURCES",
      styleName: "caregiverSources",
    },
    // {
    //   path: "/location-type",
    //   title: "LOCATION_TYPE",
    //   styleName: "locationType",
    // },
    // {
    //   path: "/sub-location-type",
    //   title: "SUB_LOCATION_TYPE",
    //   styleName: "subLocationType",
    // },
    {
      path: "/privacy-policy",
      title: "PRIVACY_POLICY",
      styleName: "privacypolicy",
    },
    {
      path: "/terms-conditions",
      title: "TERMS_&_CONDITIONS",
      styleName: "termsconditions",
    },
  ];

  return (
    <div>
      {/* Burger Icon Button */}
      <div className="d-md-none">
        <Button
          className="offsetIcon"
          variant="light"
          onClick={handleShowOffcanvas}>
          ☰
        </Button>
      </div>
      {/* Offcanvas for small devices */}
      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("MENU")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Dropdown show={dropdownOpen} autoClose={false}>
            <Dropdown.Toggle
              onClick={toggleDropdown}
              id="dropdown-button-example1"
              variant=""
              className={styles.settingDropDownText}>
              <p className={styles.settingText}>{t("SETTING")}</p>
              <img
                src={arrowUpImage}
                className={styles.locationImage}
                alt="Arrow"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.settingDropdown}>
              {dropDownItem?.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  className={item.styleName}
                  style={{
                    backgroundColor:
                      pathname === item.path ? "#5DA128" : "#757575",
                    color: pathname === item.path ? "#5DA128 " : "#757575", // White text color for both active and inactive states
                  }}
                  onClick={() => handleItemClick(item.path, index)}>
                  {t(item.title)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Sidebar for large devices */}
      <div className={`d-none d-md-block ${styles.mainSidebarParent}`}>
        <div className={`offSetScreen ${styles.offSetScreen}`}>
          <div className="setLogoOnDashboard"></div>
          <Dropdown
            show={dropdownOpen ? true : false}
            data-bs-theme=""
            autoClose={false}>
            <Dropdown.Toggle
              onClick={toggleDropdown}
              id="dropdown-button-example1"
              variant=""
              className={styles.settingDropDownText}>
              <p className={styles.settingText}>{t("SETTING")}</p>
              <img
                src={arrowUpImage}
                className={styles.locationImage}
                alt="Arrow"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={styles.settingDropdown}
              onClick={() => {
                setDropdownOpen(true);
              }}>
              {dropDownItem?.map((item, index) => {
                return (
                  <Dropdown.Item
                    className={item.styleName}
                    style={{
                      backgroundColor:
                        pathname === item.path ? "#5da12838" : "#F5F5F5",
                      color: pathname === item.path ? "#5DA128" : "#757575",
                    }}
                    onClick={() => handleItemClick(item.path, index)}>
                    {t(item.title)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Sidebar;
