import React from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { DropdownButton } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Stakeholder({ data, setPageNo }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [stakeholderList, setStakeholderList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setStakeholderList(data.stakeholderList);
      const pages = Math.ceil(data.stakeholderCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };
  const handleNameClick = (id) => {
    window.open(`/stakeholderdetails/${id}`, "_blank");
  };

  return (
    <>
      {stakeholderList?.length !== 0 ? (
        <div className={styles.evntType}>
          <Table striped bordered hover className={styles.stackDetailsTable}>
            <thead>
              <tr>
                <th>{t("NAME")}</th>
                <th>{t("EMAIL")}</th>
                <th>{t("PHONE_NUMBER")}</th>
                <th>{t("CITY")}</th>
              </tr>
            </thead>
            <tbody>
              {stakeholderList?.map((item, index) => (
                <tr key={index}>
                  {/* <td className={styles.textuppercase}>{item.name}</td> */}
                  <td
                    onClick={() => handleNameClick(item.stakeholder_id)}
                    className={`${styles.caregiverName} ${styles.personName}`}>
                    {item.name ? item.name : "NA"}
                  </td>
                  <td>{item.email ? item.email : "NA"}</td>
                  <td>
                    {item.dial_code ? item.dial_code : "NA"}{" "}
                    {item.phone_number ? item.phone_number : "NA"}
                  </td>
                  <td>{item.city ? item.city : "NA"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
      )}
      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default Stakeholder;
