import React from "react";
import styles from "./StakeholderDetails.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import { useEffect } from "react";
function AssignedSenior({ data, setPageNo }) {
  const { t } = useTranslation();
  const [assignsenior, setAssignSenior] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [totalPageno, setTotalPageno] = useState();
  useEffect(() => {
    if (totalCount) {
      const pages = Math.ceil(totalCount / 10);
      setTotalPageno(pages);
    }
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setAssignSenior(data.assignedSeniorList);
      const pages = Math.ceil(data.assignedSeniorCount / 10);
      setTotalPageno(pages);
    }
  }, [data]);
  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNo(newPageNumber);
  };

  return (
    <>
      {assignsenior?.length !== 0 ? (
        <div className={styles.evntType}>
          <Table striped bordered hover className={styles.stackDetailsTable}>
            <thead>
              <tr>
                <th className={styles.leftTableThpadding}>
                  {t("PERSON_NAME")}
                </th>
                <th className={styles.assignedSeniorRelation}>
                  {t("RELATION")}
                </th>
                <th
                  style={{
                    width: "30%",
                  }}>
                  {t("MODULE_NAME")}
                </th>
                <th className={styles.rightTableThpadding}>{t("Level")}</th>
              </tr>
            </thead>
            <tbody>
              {assignsenior?.map((item, index) => (
                <tr key={index}>
                  <td
                    className={`${styles.textuppercase} ${styles.leftTablepadding}`}>
                    {item.person_name ? item.person_name : "NA"}
                  </td>
                  <td className={styles.textuppercase}>
                    {item.relation ? item.relation : "NA"}
                  </td>
                  <td>{item.module_name ? item.module_name : "NA"}</td>
                  <td className={styles.rightTablepadding}>
                    {item.level ? item.level : "NA"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
      )}
      {totalPageno > 1 && (
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            pageCount={totalPageno} // Adjust this as per your total page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default AssignedSenior;
