import stake_image from "../../assets/image/stake_image .png";
import Persons from "../../assets/image/Persons .png";
import Caregivers from "../../assets/image/Caregivers.png";
import sites from "../../assets/image/sites.png";
import stakeholders_image from "../../assets/image/stakeholders_image.png";
import { useTranslation } from "react-i18next";
// home page mockdata
const MockData = () => {
  const { t } = useTranslation();

  const sections = [
    {
      imgSrc: stake_image,
      text: t("STAKEHOLDERS"),
      link: "/stakholderlist",
      alt: "stake_image",
      circleImgSrc: stakeholders_image,
      countKey: "stakeholdersCount",
    },
    {
      imgSrc: sites,
      text: t("SITES"),
      link: "JavaScript:void(0)",
      alt: "sites",
      countKey: "sitesCount",
    },
    {
      imgSrc: Persons,
      text: t("PERSONS"),
      link: "JavaScript:void(0)",
      alt: "person",
      countKey: "personsCount",
    },
    {
      imgSrc: Caregivers,
      text: t("CAREGIVERS"),
      link: "/caregivers-list",
      alt: "Caregivers",
      countKey: "caregiversCount",
    },
  ];

  return sections;
};

export default MockData;
export const ACTIVE_TYPE = {
  IS_ACTIVE: 1,
  IS_INACTIVE: 0,
};
